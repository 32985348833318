import React from 'react';
import { Stage, Layer, Line, Circle, Arrow } from 'react-konva';
import ImageBox from './ImageBox';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import GetNextImage from '../util/deck'
import Modal from 'react-bootstrap/Modal'

const scaleBy = 1.03;
const imageToArrowRadius = 120;

function generateArrowConnection(from, to) {
  const dx = to.x - from.x;
  const dy = to.y - from.y;
  let angle = Math.atan2(-dy, dx);


  return [
    from.x + -imageToArrowRadius * Math.cos(angle + Math.PI),
    from.y + imageToArrowRadius * Math.sin(angle + Math.PI),
    to.x + -imageToArrowRadius * Math.cos(angle),
    to.y + imageToArrowRadius * Math.sin(angle),
  ];
}
function getRelativePointerPosition(node) {
  // the function will return pointer position relative to the passed node
  var transform = node.getAbsoluteTransform().copy();
  // to detect relative position we need to invert transform
  transform.invert();

  // get pointer (say mouse or touch) position
  var pos = node.getStage().getPointerPosition();

  // now we find a relative point
  return transform.point(pos);
}

let redoLines = []
const MainDrawer = () => {
  const [tool, setTool] = React.useState('pen');
  const [size, setSize] = React.useState(5);
  const [lines, setLines] = React.useState([]);
  const [selectedId, setSelectedId] = React.useState("");
  const [canDraw, setCanDraw] = React.useState(true);
  const [images, setImages] = React.useState([])
  const [canvasSize, setCanvasSize] = React.useState({
      width: 10,
      height: 10
  });
  const [mousePosition, setMousePosition] = React.useState({x:-100, y:-100})

  const [canDrag, setCanDrag] = React.useState(false)
  const [deckImage, setDeckImage] = React.useState('')

  const [showCredits, setShowCredits] = React.useState(false);
  const handleCloseCredits = () => setShowCredits(false);
  const handleShowCredits = () => setShowCredits(true);

  const [showConfirm, setShowConfirm] = React.useState(false);
  const handleCloseConfirm = () => setShowConfirm(false);
  const handleShowConfirm = () => setShowConfirm(true);
  
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const isDrawing = React.useRef(false);
  const stageRef = React.useRef(null);
  const dragUrl = React.useRef('');
  

  const [arrows, setArrows] = React.useState([]);
  const currentArrow = React.useRef(null);

  const handleDeleteSave = () => {
    setDeckImage('')
    setArrows([])
    setLines([])
    setImagePositions([])
    handleCloseConfirm()
    setTimeout(()=>{
      window.location.reload()
    }, 1000)
  }

  const onCreateLine = (url) => {
    if(currentArrow.current && url != currentArrow.current){
      setArrows([...arrows, {
        from: currentArrow.current,
        to: url
      }])
      currentArrow.current = null;
    } else {
      currentArrow.current = url;
    } 
  }

  const [imgPositions, setImagePositions] = React.useState([]);
  const onUpdateImagePosition = (id, x, y) => {
    const newPos = {...imgPositions};
    newPos[id] = {x,y}
    setImagePositions(newPos)
  }
  React.useEffect(() => {
    const onUnload = ()=>{
      //save current state
      const saveData = {
        lastImage: deckImage,
        imagesPositions: imgPositions,
        lines,
        arrows
      }
      const jsonSave = JSON.stringify(saveData);
      window.localStorage.setItem('saveData', jsonSave)
    }
    window.addEventListener('beforeunload', onUnload)
    return () => {
      window.removeEventListener('beforeunload', onUnload)
    }
  },[deckImage, imgPositions, lines, arrows])
  React.useEffect(()=>{
    const colElement = document.getElementById("drawArea")
    const colBounds = colElement.getBoundingClientRect();
    document.addEventListener('mouseup', (e)=>{
      isDrawing.current = false;
    })
    document.addEventListener('keydown', (e) => {
      if(e.code === 'ShiftLeft'){
        setCanDrag(true)
        setCanDraw(false)
      }
    });
    window.addEventListener('resize', (e) => {
      const colElement = document.getElementById("drawArea")
      const colBounds = colElement.getBoundingClientRect();
      setCanvasSize({
        width: colBounds.width,
        height: colBounds.height
      })
    });
    document.addEventListener('keyup', (e) => {
      if(e.code === 'ShiftLeft'){
        setCanDrag(false)
        setCanDraw(true)
      }
    });
    setCanvasSize({
      width: colBounds.width,
      height: colBounds.height
    })
    let img = GetNextImage();
    const saveString = window.localStorage.getItem('saveData')
    if(saveString){
      const jsonSave = JSON.parse(saveString)
      setArrows(jsonSave.arrows)
      setLines(jsonSave.lines)
      setImagePositions(jsonSave.imagesPositions)
      while(jsonSave.lastImage !== '' && img !== jsonSave.lastImage && img !== undefined){
        const src = encodeURI(window.location.origin + img)
        images.push({
          src: src,
          x: jsonSave.imagesPositions[src]?.x,
          y: jsonSave.imagesPositions[src]?.y
        })
        img = GetNextImage()
      }
      setImages([...images])
    }
    setDeckImage(img);
  }, [])
  
  function zoomStage(event) {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = event.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      }
      stage.position(newPos);
      stage.batchDraw();
    }
  }
  const handleMouseDown = (e) => {
    if(canDraw && e.evt.which === 1 && e.target === stageRef.current){
        e.evt.preventDefault();
        isDrawing.current = true;
        const pos = getRelativePointerPosition(e.target.getStage());
        setLines([...lines, { tool, size, points: [pos.x, pos.y] }]);
    }
  };

  const handleUndo = () => {
    const last = lines.pop()
    if(last && canDraw){
      setLines([...lines]);
      redoLines.push(last)
    }
  }
  const handleRedo = () =>{
    if(redoLines.length === 0) return
    setLines([...lines, redoLines.pop()]);
  }

  const handleMouseMove = (e) => {
    const stage = e.target.getStage();
    const point = getRelativePointerPosition(stage);
    setMousePosition(point)
    // no drawing - skipping
    if (!isDrawing.current || !canDraw || lines.length === 0) {
      return;
    }
    if (selectedId !== ""){
        setSelectedId("");
    }
    e.evt.preventDefault();
    let lastLine = lines[lines.length - 1];
    if(!lastLine?.points){
      return;
    }
    // add point
    lastLine.points = lastLine.points.concat([point.x, point.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    redoLines = []
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
  };

  return (
    <Row style={{height:"100vh", marginLeft:"0"}}>
        <Col sm={2} className="bg-dark p-2">
            <div className="d-grid gap-2">
                <img style={{width:"100%"}} src="/logo.png" alt="logo"/>
                <Form.Select
                value={tool}
                onChange={(e) => {
                    setTool(e.target.value);
                }}
                >
                <option value="pen">Pen</option>
                <option value="eraser">Eraser</option>
                </Form.Select>
                <Form.Select
                value={size}
                onChange={(e) => {
                    setSize(parseInt(e.target.value));
                }}
                >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={30}>30</option>
                <option value={35}>35</option>
                <option value={40}>40</option>
                <option value={45}>45</option>
                <option value={50}>50</option>
                </Form.Select>
                <Button style={{backgroundImage:"url('/img/bricks.png')"}} variant="primary" onClick={handleUndo} size="lg">Undo</Button>
                <Button style={{backgroundImage:"url('/img/bricks.png')"}} variant="primary" onClick={handleRedo} size="lg">Redo</Button>
                <Button style={{backgroundImage:"url('/img/bricks.png')"}} variant="primary" onClick={handleShowConfirm} size="lg">Nuke</Button>

                <Modal show={showConfirm} onHide={handleCloseConfirm}>
                  <Modal.Header closeButton>
                    <Modal.Title>Are you sure?</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p><b>This will delete everything and reset your progress permanently</b></p>
                    <p><b>This cannot be undone</b></p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteSave}>
                      Delete
                    </Button>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                
                
                <Button style={{backgroundImage:"url('/img/bricks.png')"}} variant="primary" onClick={handleShow} size="lg">
                  Help
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Help</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Drag and Drop Image to Place</p>
                    <p>Double Click a picture to rotate/scale</p>
                    <p>Right Image Click To Create or Attach Arrows</p>
                    <p>Right Click an Arrow to delete it</p>
                    <p>Left Click to Draw</p>
                    <p>Press Left Shift  + Left Click to Pan</p>
                    <p>Scroll to Zoom</p>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                            
                <Button style={{backgroundImage:"url('/img/bricks.png')"}} variant="primary" onClick={handleShowCredits} size="lg">
                  Credits
                </Button>

                <Modal show={showCredits} onHide={handleCloseCredits}>
                  <Modal.Header closeButton>
                    <Modal.Title>Credits</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Created by <b>d3st1ny94</b><br/>For the people's champ<img height={40} src='/img/gpb.png' alt='Grand Poo Bear Logo'/><b>GrandPooBear</b><img height={40} src='/img/gpb.png' alt='Grand Poo Bear Logo'/></p>
                    
                    <p>Big thanks to <b>Spanio</b> for box art!<br/>Thanks <b>Thamian</b> also for box art<br/><b>The GPB Community</b></p>
                    <p></p>
                    <p>Support Me or Join my new GTA RP server:</p>
                    {/* eslint-disable-next-line */}
                    <a href='https://ko-fi.com/V7V37WVOQ' target={"_blank"}><img height='36' style={{border:0,height:36}} src='https://cdn.ko-fi.com/cdn/kofi2.png?v=3' border='0' alt='Buy Me a Coffee at ko-fi.com' /></a>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseCredits}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
                {/* <Button variant="primary" onClick={()=>{
                    setCanvasSize({...canvasSize, width: canvasSize.width + window.innerWidth})
                }} size="lg">Add Space</Button> */}
                {deckImage && <img
                  alt="Top of deck"
                  src={deckImage}
                  draggable="true"
                  className='d-none d-md-block'
                  style={{width:"100%", maxHeight:"275px"}}
                  onDragStart={(e) => {
                    setCanDraw(false)
                    dragUrl.current = e.target.src;
                  }}
                  onDragEnd={(e) => {
                    setCanDraw(true)
                  }}
                />}
                <b className='text-white px-3' style={{textDecoration:"underline"}}>YOU CANNOT PUT PICTURES BACK!</b>
                <b className='text-white px-3' style={{textDecoration:"underline"}}>RIGHT CLICK IMAGE FOR ARROWS!</b>
                <b className='text-white px-3' style={{textDecoration:"underline"}}>UNDO / REDO FOR PEN ONLY! SORRY!</b>
                <b className='d-block d-md-none text-white px-3' style={{textDecoration:"underline"}}>NOT MEANT FOR MOBILE SORRY</b>
            </div>
        </Col>
        <Col id="drawArea" sm={10} style={{overflowX:"scroll", borderLeft:"1px solid white"}} className="bg-dark" onDrop={(e) => {
          if(canDraw) return
            e.preventDefault();
            // register event position
            stageRef.current.setPointersPositions(e);
            // pop the image from the deck, redraw
            setDeckImage(GetNextImage());
            // add image
            const newPos = { ...imgPositions }
            newPos[dragUrl.current] = getRelativePointerPosition(stageRef.current)
            setImagePositions(newPos)
            setImages(
              images.concat([
                {
                  ...newPos[dragUrl.current],
                  src: dragUrl.current,
                },
              ])
            );
          }}
          onDragOver={(e) => e.preventDefault()}
        >
            <Stage
                {...canvasSize}
                onMouseDown={handleMouseDown}
                onMousemove={handleMouseMove}
                onMouseup={handleMouseUp}
                onWheel={zoomStage}
                draggable={canDrag}
                onContextMenu={(e) => {
                  if(currentArrow.current && e.target === stageRef.current){
                    e.evt.preventDefault();
                    currentArrow.current = null;
                  }
                }}
                ref={stageRef}
            >
                <Layer>
                <Circle listening={false} opacity={canDraw ? 1 : 0} x={mousePosition.x} y={mousePosition.y} radius={size/2} stroke={'white'}/>
                {images.map((img, i) => (
                    <ImageBox 
                    onUpdatePosition={onUpdateImagePosition}
                    onCreateLine={onCreateLine}
                    url={img.src} 
                    x={img.x}
                    y={img.y} 
                    isSelected={selectedId === img.src} 
                    key={img.src}
                    globalCompositeOperation={"source-over"}
                    canDraw={(bool)=>{
                        setCanDraw(bool)
                    }}
                    onSelect={()=>{
                        selectedId === img.src ? setSelectedId("") : setSelectedId(img.src);
                    }}/>
                ))}
                {currentArrow.current && <Arrow stroke='#df4b26' strokeWidth={20}
                  points={generateArrowConnection(imgPositions[currentArrow.current], mousePosition)}
                />}
                {arrows.map((arrow,i)=>
                  <Arrow key={`arrow-${i}`} stroke='#df4b26' strokeWidth={20}
                  onContextMenu={(e)=>{
                    e.evt.preventDefault();
                    arrows.splice(i, 1);
                    setArrows([...arrows])
                  }}
                  points={generateArrowConnection(imgPositions[arrow.from], imgPositions[arrow.to])}
                />
                )}
                </Layer>
                <Layer>
                {lines.map((line, i) => (
                    <Line
                    listening={false}
                    key={i}
                    points={line.points}
                    stroke="#df4b26"
                    strokeWidth={line.size}
                    tension={0.5}
                    lineCap="round"
                    globalCompositeOperation={
                        line.tool === 'eraser' ? 'destination-out' : 'source-over'
                    }
                    />
                ))}
                </Layer>
            </Stage>
        </Col>
    </Row>
  );
};
export default MainDrawer;
