import React from 'react';
import MainDrawer from './components/MainDrawer';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {

  return (
    <MainDrawer/>
  );
};
export default App;
