import React from 'react';
import { Image, Transformer } from 'react-konva';
import useImage from 'use-image';

// the first very simple and recommended way:
const ImageBox = ({url, x, y, isSelected, onSelect, canDraw, onCreateLine, onUpdatePosition}) => {
  const [image] = useImage(url);
  const [shapeProps, onUpdateShapeProps] = React.useState({x, y})
  const shapeRef = React.useRef();
  const trRef = React.useRef();
  const onChange = (data) => {
    onUpdateShapeProps(data);
    onUpdatePosition(url, data.x, data.y);
  }
  const handleDragEnd = (e) => {
    canDraw(true)
    onChange({
        ...shapeProps,
      x: e.target.x(),
      y: e.target.y(),
    });
  };
  React.useEffect(()=>{
    onUpdatePosition(url, x, y);
    // eslint-disable-next-line
  }, [])
  React.useEffect(() => {
    const adjustedWidth = Math.min(image?.width || 1, 300)
    onUpdateShapeProps(p => ({
      ...p,
      width: adjustedWidth,
      height: adjustedWidth / image?.width * image?.height || 1
    }))
  }, [image])
  React.useEffect(() => {
    if (isSelected) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [isSelected]);
  return <React.Fragment>
       <Image 
        onContextMenu={(e) => {
          e.evt.preventDefault();
          onCreateLine(url);
        }}
        onDragMove={(e)=>{
          onUpdatePosition(url, e.target.x(), e.target.y())
        }}
        {...shapeProps}      
        offsetX={image ? shapeProps.width / 2 : 0}
        offsetY={image ? shapeProps.height / 2 : 0}
        image={image} 
        draggable 
        onTap={onSelect}
        onDblClick={onSelect}
        onDragEnd={handleDragEnd} 
        onDragStart={() =>{
            canDraw(false)
        }}
        ref={shapeRef}
        onTransformStart={()=>{
            canDraw(false)
        }}
        onTransformEnd={(e) => {
            canDraw(true)
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

          // we will reset it back
          node.scaleX(1);
          node.scaleY(1);
          onChange({
            ...shapeProps,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(node.height() * scaleY),
          });
        }}
    />
    {isSelected && (
      <Transformer
        ref={trRef}
        boundBoxFunc={(oldBox, newBox) => {
          // limit resize
          if (newBox.width < 5 || newBox.height < 5) {
            return oldBox;
          }
          return newBox;
        }}
      />
    )}
    </React.Fragment>
};

export default ImageBox;